import React from 'react';
import cx from 'classnames';
import './Header.css';
import { Link } from 'react-router-dom';

interface IHeaderProps {
  backUrl?: string;
  backLabel?: string;
  description?: React.ReactNode | string;
  children?: React.ReactNode | string;
  imageUrl?: string;
  imageWidth?: string;
  imageComponent?: React.ReactNode | string;
}

export const Header = (props: IHeaderProps) => {
  const hasBackUrl = props.backUrl && props.backLabel;
  return (
    <div className="header">
      <div className="header__container wrapped">
        { hasBackUrl && <Link className="header__back" to={props.backUrl as any}>&laquo; { props.backLabel }</Link> }
        <div className={cx('header__content', !hasBackUrl && 'header__container--large-margin')}>
          <div className="header__description">
            { props.description }
            { props.children }
          </div>
          { props.imageUrl && <div className="header__image" style={{ flex: `0 0 ${props.imageWidth ?? '350px'}`, backgroundImage: `url(${props.imageUrl})` }}></div> }
          { props.imageComponent && <div className="header__image" style={{ flex: `0 0 ${props.imageWidth ?? '350px'}` }}>{ props.imageComponent }</div> }
        </div>
      </div>
    </div>
  );
}
