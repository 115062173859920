import React, {useState} from 'react';
import {Input} from 'antd';
import './Home.css';
import {Link} from 'react-router-dom';
import {CriblLogoIcon, TutorialIcon} from './svg';
import {Navbar} from './Navbar';
import {Header} from './Header';
import * as config from './config';
import useSWR, { preload } from 'swr';
import {CriblCon} from "./components/hotfix/hotfix";

const fetcher = (url: string) => fetch(url).then((res) => res.json());
preload('/api/v1/sandbox/courses.json', fetcher);

const { Search } = Input;

export interface OutlineItem {
  type: 'group' | 'item';
  id: string;
  label: string;
  content?: string;
}

export interface ICourseInfo {
  id: string;
  title: string;
  subtitle?: string;
  duration: string;
  description: string;
  outline?: OutlineItem[];
  overview?: string;
  imageUrl: string;
  imageWidth?: string;
  type: 'course' | 'tutorial' | 'demo' | 'deepDive' | 'redirect' | 'hidden' | 'event' | 'hiddendemo';
  tabs?: string[];
  tabTitles?: string[];
  startupTime?: number;
  showTerminal?: boolean;
  showStream?: boolean;
  showDocs?: boolean;
  target?: string;
  cloud?: boolean;
  provider?: {}
}

const CourseBox = (props: { course:ICourseInfo }) => {
  return (
    <Link to={`/course/${props.course.id}`}>
      <div className='course-box'>
        <div className='course-box__top-box'>
          <div className='course-box__image' style={{backgroundImage:`url(${props.course.imageUrl})`}}></div>
        </div>
        <div className='course-box__bottom-box'>
          <div className='course-box__title'>{props.course.title}</div>
          <div className='course-box__subtitle'>{props.course.subtitle}</div>
          <div className='course-box__time'>{props.course.duration}</div>
        </div>
      </div>
    </Link>
  );
}

const TutorialBox = (props: { course:ICourseInfo }) => {
  return (
    <Link to={`/course/${props.course.id}`}>
      <div className='tutorial-box'>
        <div className='tutorial-box__top-box'>
          <div className='tutorial-box__image'>
            <TutorialIcon height={25} />
          </div>
        </div>
        <div className='tutorial-box__bottom-box'>
          <div className='tutorial-box__title'>{props.course.title}</div>
          <div className='tutorial-box__time'>{props.course.duration}</div>
        </div>
      </div>
    </Link>
  );
}

const searchCourses = (courses: ICourseInfo[], query: string) => {
  return courses.filter(course => course?.title?.toLowerCase().includes(query.toLowerCase()));
};

export const Home = () => {
  const { data, isLoading } = useSWR<ICourseInfo[]>(`/api/v1/sandbox/courses.json`, fetcher);
  const [search, setSearch] = useState('');
  const courselist = (data || []).filter(c => c.type==='course')
  const tutorials = (data || []).filter(c => c.type==='tutorial');
  const demos = (data || []).filter(c => c.type==='demo');
  const deepDives = (data || []).filter(c => c.type==='deepDive');
  const showSearch =  (data || []).length > 8 || tutorials.length > 8;
  const filteredCourses = showSearch ? searchCourses(courselist || [], search) : data || [];
  const filteredTutorials = showSearch ? searchCourses(tutorials, search) : tutorials || [];
  const filteredDemos = showSearch ? searchCourses(demos, search) : demos || [];
  const filteredDeepDives = showSearch ? searchCourses(deepDives, search) : deepDives || [];
  const fromDemo = config.demoHosts.includes(window.location.hostname);
  //console.log(`Hostname Location: ${window.location.hostname}`);
  React.useEffect(() => {
    document.title = fromDemo ? 'Cribl Demo' : 'Cribl Sandbox';
  }, [fromDemo]);
  if (isLoading) return (<></>);
  return (
    <div>
      <CriblCon />
      <Navbar />
      <Header
        description={
          <div>
            <h1>Cribl {fromDemo ? 'On-Demand Demo Environments' : 'Sandboxes'}</h1>
            <p>Welcome! {fromDemo ? 'On-Demand Demo Environments offer a flexible way to demo the Cribl Suite of products! For any issues or questions please use #team-sandbox-eng in Slack!' : 'Cribl is the Data Engine for IT and Security. Cribl Sandboxes are online tutorials to help you learn about Cribl products. We recommend you start with our Cribl Stream Overview, which covers basic product usage and includes a tour of some common use cases.'}</p>
            <p>For more in-depth Courses and Certifications, please visit <a href='https://cribl.io/university/' target="_blank" rel="noopener noreferrer">Cribl University</a></p>
          </div>
        }
        imageComponent={
          <CriblLogoIcon height={250} className="home__header-icon" />
        }
      />

      <div className="wrapped home">
        {
          showSearch &&
          <div className="home__search">
            <Search placeholder="Search by keyword" value={search} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)} enterButton style={{ width: 300 }}/>
          </div>
        }
        { !fromDemo && 
        <div className="home__courses-section">
          <h2 className="home__tiles-title">Overviews</h2>
          <p className="home__tiles-subtitle">Get started with these high-level introductions to the Cribl Suite of products</p>
          <div className="home__tiles home__tiles--courses">
            { filteredCourses.map(c => <CourseBox key={c.id} course={c} /> )}
            { filteredCourses.length === 0 && <div className="home__no-results">No courses found</div> }
          </div>
        </div>
        }
        { !fromDemo && 
        <div className="home__courses-section">
          <h2 className="home__tiles-title">How-To's</h2>
          <p className="home__tiles-subtitle">Only got 10 minutes? No problem! Come see how to do basic tasks in the Cribl Suite of products in these short shorts. Nice!</p>
          <div className="home__tiles home__tiles--tutorials">
            { filteredTutorials.map(t => <TutorialBox key={t.id} course={t} /> )}
            { filteredTutorials.length === 0 && <div className="home__no-results">No tutorials found</div> }
          </div>
        </div>
        }
        { !fromDemo &&
        <div className="home__courses-section">
          <h2 className="home__tiles-title">Deep Dives</h2>
          <p className="home__tiles-subtitle">Jump in and learn more in these hyper-focused sandboxes</p>
          <div className="home__tiles home__tiles--tutorials">
            { filteredDeepDives.map(t => <TutorialBox key={t.id} course={t} /> )}
            { filteredDeepDives.length === 0 && <div className="home__no-results">No deep dives found</div> }
          </div>
        </div>
        }
        { fromDemo && 
        <div className="home__courses-section">
          <h2 className="home__tiles-title">Demos</h2>
          <p className="home__tiles-subtitle">On-Demand Demo Environments</p>
          <div className="home__tiles home__tiles--courses">
            { filteredDemos.map(c => <CourseBox key={c.id} course={c} /> )}
            { filteredDemos.length === 0 && <div className="home__no-results">No demos found</div> }
          </div>
        </div>
        }
      </div>
    </div>
  );
};

