import React, {useEffect} from 'react';
import {ICourseLocationState} from "./types";
import {useLocation} from "react-router-dom";
import {getCoursesOrigin} from "./cloudSandboxUtil";


interface HealthProbeData {
    status: string;
    appName: string;
    timestamp: number;
}

export const HealthCheckMonitor: React.FC = () => {
    const location = useLocation();
    const {course} = location.state as ICourseLocationState;

    useEffect(() => {
        if (!course.cloud) {
            console.debug('HealthCheckMonitor: disabled');
            return;
        }

        console.debug('HealthCheckMonitor: enabled');
        const HEALTH_CHECK_INTERVAL_MILLIS: number = 1_000;
        const PROBE_TIMEOUT_PORTAL_MILLIS: number = 10_000;
        const PROBE_TIMEOUT_LEADER_MILLIS: number = 3_000;
        const KEY_LAST_HEALTH_PROBE: string = `sandbox_${course.id}_last_health_probe`;

        sessionStorage.removeItem(KEY_LAST_HEALTH_PROBE);

        const onMessageHandler = (event: MessageEvent) => {
            try {
                const data: HealthProbeData = JSON.parse(event.data);
                if (data.status === "ok") {
                    data.timestamp = Date.now();
                    sessionStorage.setItem(KEY_LAST_HEALTH_PROBE, JSON.stringify(data));
                }
            } catch(e) {
                // intentionally debug for potential investigations, no need to include this in sentry logs
                console.debug('HealthCheckMonitor: message handler error:', event, e);
            }
        };

        window.addEventListener("message", onMessageHandler);

        function checkCloudHealth() {
            try {
                const lastHealthProbe: HealthProbeData = JSON.parse(sessionStorage.getItem(KEY_LAST_HEALTH_PROBE) || '');
                let failureTimeout: number = 0;
                if (lastHealthProbe && lastHealthProbe.timestamp > 0) {
                    if (lastHealthProbe.appName === 'CloudPortal') {
                        failureTimeout = PROBE_TIMEOUT_PORTAL_MILLIS;
                    } else {
                        failureTimeout = PROBE_TIMEOUT_LEADER_MILLIS;
                    }
                }
                if (failureTimeout > 0) {
                    const nowMillis: number = Date.now();

                    if (nowMillis - lastHealthProbe.timestamp > failureTimeout) {
                        console.error(`HealthCheckMonitor: health probe failed: ${nowMillis - lastHealthProbe.timestamp} ms, failure timeout: ${failureTimeout} ms`);

                        const coursesOrigin: string = getCoursesOrigin(window.location.origin);

                        window.location.href = `${coursesOrigin}/course/${course.id}`;
                        return;
                    }
                }
            } catch {
                // no valid health probe found, ignore
            }

            const cloudFrame = document.getElementById('idFrame0') as HTMLIFrameElement | undefined;

            if (cloudFrame) {
                const message = {
                    request: "healthcheck"
                };
                cloudFrame.contentWindow?.postMessage(JSON.stringify(message), "*");
            }
        }

        const intervalId = setInterval(checkCloudHealth, HEALTH_CHECK_INTERVAL_MILLIS);

        return () => {
            clearInterval(intervalId);
            window.removeEventListener('message', onMessageHandler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};
