import React from 'react';
import {Router, Switch, Route} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {Home} from './Home';
import {Course} from './Course';
import {UI} from './UI';
import {Callback} from "./Callback";
import {HealthCheckMonitor} from "./HealthCheckMonitor";
import {ImageEventListener} from "./components/ImageEventListener/ImageEventListener";

const history = createBrowserHistory();

export default function App() {
    return (
        <ImageEventListener>
            <Router history={history}>
                <Switch>
                    <Route path="/callback/course/:id">
                        <Callback/>
                    </Route>
                    <Route path="/course/:id">
                        <Course/>
                    </Route>
                    <Route path="/tutorial/:id">
                        <Course/>
                    </Route>
                    <Route path="/ui">
                        <HealthCheckMonitor/>
                        <UI/>
                    </Route>
                    <Route path="/">
                        <Home/>
                    </Route>
                </Switch>
            </Router>
        </ImageEventListener>
    );
}
