import React from 'react';
import './Navbar.css';
import { CriblLogo } from './svg';

export const Navbar = () => {
  return (
    <div className="navbar__header">
      <div className='navbar__header-logo wrapped'>
        <a href="https://cribl.io/"><CriblLogo height={22} /></a>
      </div>
    </div>
  );
}
