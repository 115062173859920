import React from 'react';

export interface ISvgIconProps {
  width?: number | string;
  height?: number | string;
  fill?: string;
  style?: React.CSSProperties;
  className?: string;
}

export const CriblLogoIcon: React.FunctionComponent<ISvgIconProps> = (props: ISvgIconProps) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 79.3 92.8">
  <g>
    <g>
        <polygon fill="#ffffff"  points="0,53.6 0,49.1 79.3,49.1 42.5,69.4"/>
        <polygon fill="#ffffff"  points="36.3,72.8 0,92.8 0,59.3"/>
    </g>
    <g>
        <polygon fill="#ffffff"  points="0,39.2 0,43.7 79.3,43.7 42.5,23.4"/>
        <polygon fill="#ffffff"  points="36.3,20 0,0 0,33.5"/>
    </g>
  </g>
  </svg>
);

export const CriblLogo: React.FunctionComponent<ISvgIconProps> = (props: ISvgIconProps) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 347.93 92.8">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Artwork_Guidelines" data-name="Artwork Guidelines">
        <polygon fill="#0cc" points="0 53.61 0 49.07 79.25 49.07 42.49 69.35 0 53.61"/>
        <polygon fill="#0cc" points="36.32 72.76 0 92.8 0 59.31 36.32 72.76"/>
        <polygon fill="#0cc" points="0 39.18 0 43.73 79.25 43.73 42.49 23.45 0 39.18"/>
        <polygon fill="#0cc" points="36.32 20.04 0 0 0 33.49 36.32 20.04"/>
        <path fill="#fff"  d="M127.84,12.5A31.7,31.7,0,0,1,139,14.23a30,30,0,0,1,8.48,4.93L141.77,26a19.9,19.9,0,0,0-6.28-3.64,21.25,21.25,0,0,0-7.13-1.18,17.24,17.24,0,0,0-9,2.45,17,17,0,0,0-6.59,8q-2.48,5.52-2.48,14.74,0,9,2.36,14.55a16.73,16.73,0,0,0,6.47,8,17.38,17.38,0,0,0,9.22,2.5,19.38,19.38,0,0,0,8.69-1.72,32.13,32.13,0,0,0,6.09-3.9l5.29,6.76A28.3,28.3,0,0,1,128,80.3a28.62,28.62,0,0,1-14.86-3.9,27,27,0,0,1-10.39-11.46q-3.81-7.56-3.81-18.61t3.92-18.5a27.36,27.36,0,0,1,25-15.33Z"/>
        <path fill="#fff" d="M196,28.21a20.11,20.11,0,0,0-4-.36,16.58,16.58,0,0,0-10.65,3.29,24.63,24.63,0,0,0-6.92,9.56l-1.94-11.57h-15.4v7.51h7.32V71.46h-7.32V79h28V71.46H175V52.94q2.26-7.45,6.21-11.53a12.69,12.69,0,0,1,9-4V47.51h7.56l1.79-18.38A31.43,31.43,0,0,0,196,28.21Z"/>
        <path fill="#fff" d="M233.17,29.13V71.27h16.05V79H207.66V71.27h14.93V36.88H208.13V29.13ZM225.8,4.71a6.84,6.84,0,0,1,5,1.89,6.35,6.35,0,0,1,1.92,4.72,6.51,6.51,0,0,1-1.92,4.77,7.4,7.4,0,0,1-10,0,6.49,6.49,0,0,1-1.94-4.77,6.33,6.33,0,0,1,1.94-4.72A6.81,6.81,0,0,1,225.8,4.71Z"/>
        <path fill="#fff" d="M270.73,35.08a19.66,19.66,0,0,1,6.07-5.33,15.33,15.33,0,0,1,7.63-1.94q6.57,0,10.65,3.23a18.54,18.54,0,0,1,6,9.12A44.65,44.65,0,0,1,303,54a38,38,0,0,1-2.27,13.56A20.72,20.72,0,0,1,294,76.89a17.29,17.29,0,0,1-10.89,3.41A15.68,15.68,0,0,1,270,74.11L269.45,79h-9.3V9.1l10.58-1.18Zm9.78,37.14a9.48,9.48,0,0,0,8.34-4.4q2.94-4.39,2.95-13.79a37.78,37.78,0,0,0-1.18-10.37,11.48,11.48,0,0,0-3.47-5.84,8.71,8.71,0,0,0-5.6-1.84,9.79,9.79,0,0,0-6.24,2.13,20.84,20.84,0,0,0-4.58,5V66.45A13.34,13.34,0,0,0,275,70.68,10.26,10.26,0,0,0,280.51,72.22Z"/>
        <path fill="#fff" d="M328.84,9.1V65.41q0,3.45,2.1,4.89a9.47,9.47,0,0,0,5.46,1.45,17,17,0,0,0,4.11-.48,30.67,30.67,0,0,0,3.78-1.18L347,77.46a27.29,27.29,0,0,1-5.46,2,29.44,29.44,0,0,1-7.39.85q-7.51,0-11.69-4.23t-4.18-11.6V16.89H305.67V9.1Z"/>
        <path fill="#fff" d="M344,11.67l-1.08-1.95h-.75v1.95h-.89v-5H343c1,0,1.84.37,1.84,1.49a1.41,1.41,0,0,1-1,1.43L345,11.67ZM342.18,9h.7c.69,0,1.05-.29,1.05-.85s-.36-.77-1.05-.77h-.7Z"/><circle fill="none" stroke="#fff" strokeMiterlimit="10"  cx="343" cy="9.1" r="4.43"/>
      </g>
    </g>
  </svg>

);

export const TutorialIcon: React.FunctionComponent<ISvgIconProps> = (props: ISvgIconProps) => (
  <svg {...props} viewBox="0 0 26 26">
    <g fill="none" fillRule="evenodd" transform="translate(.5 .5)">
      <circle cx="12.5" cy="12.5" r="11.75" stroke="#FFF" strokeWidth="1.5"/>
      <path fill="#FFF" d="M7.254 15.773c3.02.624 4.647.988 4.879 1.092.128.052.257.122.367.19v-8.07c-.165-.244-.349-.417-.606-.538-.444-.374-1.991-.72-4.64-1.04v8.366zm-.483-7.257v7.517c0 .156.121.26.26.26 3.087.552 4.832 1.042 5.235 1.47.087.225.381.225.468.017.419-.44 2.164-.935 5.235-1.487.139 0 .26-.104.26-.26V8.516h.59V16.9c-3.56.564-5.472 1.105-5.738 1.625h-1.162c-.266-.52-2.178-1.061-5.738-1.625V8.516h.59zm10.956-1.109v8.366c-3.191.636-4.934 1.063-5.227 1.283V8.984c.146-.243.33-.416.587-.537.468-.374 2.015-.72 4.64-1.04z"/>
      <path fill="#00D3E8" d="M11.159 12.566l-.03-.144c-.024-.108.013-.221.095-.295.04-.037.09-.062.143-.073l.052-.011c.018-.113.053-.221.105-.322l-.036-.04c-.114-.127-.104-.323.023-.438l.11-.099c.127-.114.324-.104.438.024l.036.039c.106-.041.217-.065.331-.07l.016-.05c.017-.053.047-.1.087-.136.083-.074.199-.099.304-.065l.14.046c.163.053.253.228.2.392l-.016.05c.044.035.086.074.123.116.038.042.073.088.104.135l.05-.01c.168-.036.334.07.369.239l.03.143c.023.109-.014.222-.096.296-.04.036-.09.062-.143.073l-.052.01c-.017.113-.052.222-.104.323l.035.04c.115.127.105.323-.023.438l-.109.098c-.127.115-.324.104-.438-.023l-.036-.04c-.106.042-.218.065-.331.071l-.017.05c-.016.052-.046.1-.087.136-.082.074-.198.098-.304.064l-.14-.045c-.163-.053-.252-.229-.2-.392l.017-.05c-.044-.035-.086-.074-.123-.116-.038-.042-.073-.088-.104-.135l-.051.01c-.168.036-.333-.071-.368-.239zm1.566-.085c.15-.135.161-.365.026-.515-.134-.15-.365-.162-.514-.027-.15.135-.162.365-.027.515.135.15.365.161.515.027zm2.504-.221v-.074c-.34-.032-.523-.194-.523-.582v-.584c0-.385-.122-.513-.537-.513h-.049c-.154 0-.28-.125-.28-.28 0-.155.126-.28.28-.28h.203c.73 0 1.089.239 1.089.948v.457c0 .356.08.52.425.52h.083c.194 0 .352.158.352.352 0 .194-.158.352-.352.352h-.083c-.345 0-.425.162-.425.52v.537c0 .714-.358.969-1.089.969h-.203c-.154 0-.28-.126-.28-.28 0-.155.126-.28.28-.28h.049c.415 0 .537-.128.537-.513v-.64c0-.387.183-.597.523-.63zm-5.458 0c.34.032.523.242.523.63v.639c0 .385.123.513.537.513h.049c.154 0 .28.125.28.28 0 .154-.126.28-.28.28h-.203c-.73 0-1.089-.255-1.089-.97v-.536c0-.358-.08-.52-.425-.52H9.08c-.194 0-.352-.158-.352-.352 0-.194.158-.352.352-.352h.083c.346 0 .425-.164.425-.52v-.457c0-.71.359-.948 1.089-.948h.203c.154 0 .28.125.28.28 0 .155-.126.28-.28.28h-.049c-.414 0-.537.128-.537.513v.584c0 .388-.183.55-.523.582v.074z"/>
    </g>
  </svg>
);
