const ORIG_SANDBOX_IO: string = "https://sandbox.cribl.io";
const ORIG_SANDBOX_STAGE_IO: string = "https://sandbox-test.cribl.io";
const ORIG_SANDBOX_CLOUD: string = "https://sandbox.cribl.cloud";
const ORIG_SANDBOX_STAGE_CLOUD: string = "https://sandbox.cribl-staging.cloud";

// For cloud sandboxes redirect back to the .cloud domain
export const getCallbackOrigin = (origin: string): string => {
  if (origin === ORIG_SANDBOX_IO) {
    return ORIG_SANDBOX_CLOUD;
  } else if (origin === ORIG_SANDBOX_STAGE_IO) {
    return ORIG_SANDBOX_STAGE_CLOUD;
  }

  return origin;
}

// For cloud sandboxes return to the main page on .cribl.io domain
export const getCoursesOrigin = (origin: string): string => {
  if (origin === ORIG_SANDBOX_CLOUD) {
    return ORIG_SANDBOX_IO;
  } else if (origin === ORIG_SANDBOX_STAGE_CLOUD) {
    return ORIG_SANDBOX_STAGE_IO;
  }

  return origin;
}