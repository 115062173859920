import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';

const PROJECT_ID_FROM_PIPELINE = 2;

Sentry.setTag('project', 'sandbox-ui');
Sentry.init({
    dsn: `http://dummy@${window.location.host}/${PROJECT_ID_FROM_PIPELINE}`,
    tunnel: '/api/v1/sentry/tunnel',
    environment: window.location.host,
    tracesSampleRate: 1,
    integrations: [
        new CaptureConsole({
            levels: ['error']
        })
    ]
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
