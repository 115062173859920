import getBrowserFingerprint from 'get-browser-fingerprint';
(window as any).gbfp = getBrowserFingerprint;
const apiUrl = '/api/v1/sandbox';
let connectPromise: Promise<string>;

export function fakeCreateSandbox(email:string, course:string):Promise<string> {
  return new Promise<string>((resolve, reject) => {
    //setTimeout(() => resolve('https://www.google.com'), 10000);
    setTimeout(() => reject(new Error('Shit happened')), 10000);
  });
}

export function getCloudRedirect(course: string): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    fetch(`${apiUrl}/redirect`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({course}),
    }).then((response) => { // eslint-disable-line
      if (!response.ok) {
        return response.text()
            .then((err) => {
              throw new Error(err);
            });
      }
      resolve(response.text());
    }).catch((err) => {
      const errmsg = err.message;
      console.error('error received from sandbox API: ', errmsg);
      reject(err);
    });
  });
}

export function createSandbox(email:string, course:string, maxStartupTime: number, verificationCode:string, ttl:string, refHost:string):Promise<string> {
  const startTime = Date.now();
  const logActivity = (state: string) => {
    const timeSinceStart = Math.floor((Date.now() - startTime)/1000);
    fetch(`${apiUrl}/log`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        course,
        maxStartupTime,
        ttl,
        timeSinceStart,
        state,
      }),
    });
  };
  if (!connectPromise) {
    connectPromise = new Promise<string>((resolve, reject) => {
      const maxRetries = maxStartupTime / 10;
      const body:any = {
        email,
        course,
        verificationCode,
        ttl
      };
      body.orig_url = `${window.location.protocol}//${window.location.host}`;
      const bodyJson = JSON.stringify(body);
      console.log(`BODY: ${bodyJson}`);
    
      const minutes  = maxStartupTime / 60;

      const checkStatusRetryCount = (body:any, retryCount:any) => {
        if (retryCount >= maxRetries) {
          logActivity('error');
          throw new Error(`Course failed to show healthy status after ${minutes} minutes. Please try refreshing the page or contact support (sandbox@cribl.io) if the issue persists.`);
        }
        return new Promise((resolve) => {
          setTimeout(() => {
            return resolve(getStatus(body, ++retryCount));
          }, 10000);
        });
      };
    
      const getStatus = (body:any, retryCount:any) => {
        logActivity('status');
        return fetch(`${apiUrl}/status`, {
          method: 'POST',
          mode: 'cors',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
          },
          body: bodyJson,
        }).catch((err) => {
          return checkStatusRetryCount(body, retryCount);
        }).then((response:any) => {
          if (response.status !== 200) {
            return checkStatusRetryCount(body, retryCount);
          }
          return Promise.resolve(response);
        }).then((response:any) => {
          return response.text()
            .then((text:string) => {
              logActivity('complete');
              console.log('ip: ', text);
              resolve(text);
              //window.location.href = href;
            });
        });
      };
    
      console.log(`BODY: ${JSON.stringify(body)}`);
  
      logActivity('create');
  
      fetch(`${apiUrl}/create`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'xreferredfrom': refHost,
        },
        body: JSON.stringify(body),
      }).then((response) => { // eslint-disable-line
        if (!response.ok) {
          return response.text()
            .then((err) => {
              throw new Error(err);
            });
        }
        return getStatus(body, 0);
      }).catch((err) => {
        const errmsg = err.message;
        if (errmsg.match(/already has a running sandbox/)) {
          return getStatus(body, 0);
        }
        console.error('error received from sandbox API: ', errmsg);
        reject(err); 
      });
  
    });
  }
  return connectPromise;
};
