import {useHistory, useLocation, useParams} from "react-router-dom";
import queryString from 'query-string'
import React from "react";
import {ICourseInfo} from "./Home";
import useSWR, {preload} from "swr";

const fetcher = (url: string) => fetch(url).then((res) => res.json());
preload('/api/v1/sandbox/courses.json', fetcher);

export const Callback = () => {
    const {search} = useLocation();
    const {id}: { id: string } = useParams();
    const searchParams = queryString.parse(search);
    const history = useHistory();
    const cloudUrl = searchParams.cloudUrl;
    const email = searchParams.userEmail;
    const { data, isLoading } = useSWR<ICourseInfo[]>(`/api/v1/sandbox/courses.json`, fetcher);
    if (isLoading) return (<></>);
    const course = data?.find(c => c.id===id);

    // Fire for zaps
    fetch(`/api/v1/sandbox/create`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email, course: id, ttl: 86400})
    }).then(() => {}).catch((error) => console.error(error));

    history.replace("/ui", {id, host: undefined, email, course, ttl: 86400, cloudUrl})

    return (<></>);
}