import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SplitPane from 'react-split-pane';
import Iframe from 'react-iframe';
import { Affix, Button, Tabs } from 'antd';
import { CloseOutlined, CodeOutlined, FileTextOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import * as config from './config';


import './UI.css';
import {ICourseLocationState} from "./types";
import {getCoursesOrigin} from "./cloudSandboxUtil";

declare global {
  interface Window { 
    course: string;
    origUrl: string;
    email: string;
  }
}

const { TabPane } = Tabs;

type ITerminalProps = {
  closeCallback: Function,
  host: string,
};

// export const userhash = (email: string) => {
//   return (window as any).CryptoJS.MD5(email).toString().substr(0, 8);
// }

const getHost = (host: string, port: string) => {
  return `port-${port}-${host}.${getDomain()}`;
}

const getDomain = () => window.location.hostname;

const TerminalIFrame = (props: ITerminalProps) => {
  const host = getHost(props.host, '27425');
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Iframe url={`${window.location.protocol}//${host}/`}
        width="100%"
        height="100%"
        id="terminalIframe"
      />
      <Affix style={{ position: 'absolute', top: '10px', right: '20px' }}>
        <Button size='small' shape='circle' icon={<CloseOutlined />} ghost={true} onClick={() => { props.closeCallback(); }} />
      </Affix>
    </div>
  );
};

type IMainWindow = {
  height: number,
  width: number | string,
  host: string,
  terminal: {
    show: boolean,
    enable: boolean
  },
}

const MainWindow = (props: IMainWindow) => {
  const host = getHost(props.host, '9000');
  const enableTerminal = props.terminal.enable;
  const [showTerminal, setShowTerminal] = useState(props.terminal.show);

  return (
    <div style={{ position: 'relative', height: props.height, width: props.width }}>
      <SplitPane split="horizontal" minSize={'5%'} size={enableTerminal && showTerminal ? '75%' : '100%'}>
        <div style={{ width: '100%', height: '100%' }}>
          <Iframe url={`${window.location.protocol}//${host}/login?username=admin&password=cribldemo`}
            width="100%"
            height="100%"
            id="criblIframe"
          // className="myClassname" 
          />
          {enableTerminal && !showTerminal && <Affix style={{ position: 'absolute', bottom: '15px', left: '15px' }}>
            <Button style={{backgroundColor: '#0cc', border: '#0cc'}} size='large' type='primary' shape='circle' icon={<CodeOutlined />} ghost={false} onClick={() => { setShowTerminal(true); }} />
          </Affix>}

        </div>
        <div style={{ width: '100%', height: '100%' }}>{enableTerminal && showTerminal && <TerminalIFrame host={props.host} closeCallback={() => { setShowTerminal(false) } }/>}</div>
      </SplitPane>
    </div>
  )
};

export const UI = () => {
  const [mainHeight, setMainHeight] = useState(0);
  const [mainWidth, setMainWidth] = useState<number | string>(0);
  const location = useLocation();
  const { id, host, email, course, ttl, cloudUrl } = location.state as ICourseLocationState;
  const [showDocs, setShowDocs] = useState(course.showDocs === undefined ? true : course.showDocs);
  const fromDemo = config.demoHosts.includes(window.location.hostname);

  useEffect(() => {
    console.log({id, host, email, course, ttl, cloudUrl});

    window.sessionStorage.setItem("origUrl", getCoursesOrigin(window.location.origin));
    window.sessionStorage.setItem("course", id);
    window.sessionStorage.setItem("email", email);
    window.sessionStorage.setItem("ttl", ttl);
  }, [id, host, email, course, ttl, cloudUrl]);

  //const qs = `origUrl=${encodeURIComponent(window.origin)}&course=${encodeURIComponent(id)}&email=${encodeURIComponent(email)}&ttl=${encodeURIComponent(ttl)}`;
  let docsUrl = `/coursedocs/${id}/docs/intro1`; //?${qs}`;

  const resetSize = () => {
    setMainWidth(0);
    setMainHeight(0);
  }

  const showStream = course.showStream ?? true;
  let main = <MainWindow host={host} height={mainHeight} width={mainWidth} terminal={{show: course.showTerminal ?? true, enable: course.enableTerminal ?? true}} />;
  let tabs: Array<object> = [];
  let tabTitles: Array<string> = [];
  if (course.tabs) {
    if (showStream) {
      tabs = [main];
      tabTitles = ['Cribl'];
    }
    tabs = [...tabs, ...course.tabs.map((expression: string, index: number) => {
      const frameId: string = `idFrame${index}`
      // eslint-disable-next-line no-new-func
      const iframeUrl = new Function('host', 'domain', 'cloudUrl', 'return `'+expression+'`;')(host, getDomain(), cloudUrl);
      console.log(`Creating tab ${index} at url="${iframeUrl}" for host=${host} and domain=${getDomain()} with expression="${expression}"`);
      return (<Iframe id={frameId} url={iframeUrl} width={`${mainWidth}px`} height={`${mainHeight}px`} />);
    })]
    tabTitles = [...tabTitles, ...course.tabTitles ? course.tabTitles : []];
  }
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current } = containerRef;

    const setSize = () => {
      if (current?.childNodes[0]) {
        const newHeight = window.innerHeight - (current.childNodes[0] as HTMLDivElement).clientHeight;
        setMainHeight(newHeight);
        setMainWidth(current.clientWidth);
      } else if (current) {
        setMainHeight(current.clientHeight);
        setMainWidth(current.clientWidth);
      }
    };
    if (mainHeight === 0) setSize();

    const debounceResetSize = debounce(resetSize, 250);
    window.addEventListener('resize', debounceResetSize);
    current?.addEventListener('resize', debounceResetSize);
    return () => { 
      window.removeEventListener('resize', debounceResetSize);
      current?.removeEventListener('resize', debounceResetSize);
    };
  });

  if (tabs.length > 0) {
    main = (<div><Tabs defaultActiveKey="1">
      {(tabTitles || []).map((t, idx) => <TabPane tab={t} key={`${idx+1}`}>{tabs[idx]}</TabPane>)}
    </Tabs></div>)
  }

  return (
    <div style={{ width: '100%', height: '100vh' }}>

      <SplitPane split="vertical" minSize={'5%'} size={(!fromDemo || showDocs) ? '30%' : '0%'} onDragFinished={resetSize}>

        <div style={{ width: '100%', height: '100%' }}> { (!fromDemo || showDocs) && <Iframe url={docsUrl}
          width="100%"
          height="100%"
          id="docsIframe"
          // className="myClassname" 
          />}
         { fromDemo && <Affix style={{ position: 'absolute', top: '10px', right: '10px' }}>
            <Button style={{backgroundColor: '#0cc', border: '#0cc'}} size='middle' shape='circle' icon={<CloseOutlined />} onClick={() => { setShowDocs(false); setMainWidth('100%');  }} />
          </Affix>}
        </div>        
        <div ref={containerRef}>
          {main}
          {(fromDemo && !showDocs) && <Affix style={{ position: 'absolute', bottom: '15px', left: '15px'}}>
          <Button style={{backgroundColor: '#0cc', border: '#0cc'}} size='large' type='primary' shape='circle' icon={<FileTextOutlined />} ghost={false} onClick={() => { setShowDocs(true); }} />
        </Affix>}
        </div>
      </SplitPane>
    </div>
  );
};
