import React, {useEffect} from 'react';
import './ImageEventsListener.css';
// @ts-ignore no typings for this library: https://github.com/biati-digital/glightbox/issues/405
import gLightbox from 'glightbox';

const PICTURE_CLICK_EVENT_PREFIX = 'picture-click:';

const lightbox = gLightbox({
    skin: 'clean'
});

export const ImageEventListener = ({children}: React.PropsWithChildren<unknown>) => {
    useEffect(() => {
        const onMessageHandler = (event: MessageEvent) => {
            if (typeof event.data !== 'string' || !event.data.startsWith(PICTURE_CLICK_EVENT_PREFIX)) {
                return;
            }

            const src = event.data.split(PICTURE_CLICK_EVENT_PREFIX).at(1);

            if (!src) {
                return;
            }

            const url = new URL(src);

            lightbox.setElements([{
                'href': url,
                'type': 'image',
            }]);
            lightbox.open();
        };

        window.addEventListener('message', onMessageHandler);

        return () => {
            window.removeEventListener('message', onMessageHandler)
        }
    }, [])

    return (
        <>
            {children}
        </>
    );
};
