import React from 'react';
import "./hotfix.css";
import Banner from "./Browser_banner_v3.png"
export const CriblCon = () => {
  const containerStyle = {
    backgroundColor: '#000000',
    textAlign: 'center' as 'center',
  };

  const safariContainerStyle = {
    backgroundColor: '#FF6B00',
    textAlign: 'center' as 'center',
  };

  // const imageStyle = {
  //   width: '350px',
  //   height: 'auto',
  // };
  const isSafari172OrLater = /Version\/17.[^10].*\sSafari/.test(navigator.userAgent);

  return (
      <div style={isSafari172OrLater ? safariContainerStyle : containerStyle}>
        {isSafari172OrLater && (
            <img src={Banner} alt={"Be careful! Mosquitos"}/>
        )}
      </div>
  );
}
